<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('companyWebsites.companyWebsites')"
                icon="mdi-web"
            >
                <template v-slot:buttons>
                    <v-btn
                        v-if="isInRole(roles.companyWebsite.create)"
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-row class="pa-10" no-gutters justify="center">
                <v-col
                    v-for="website in websites"
                    :key="website.CompanyWebsiteGuid"
                    cols="auto"
                >
                    <WebsiteCard
                        :deleteItem="deleteItem"
                        :editItem="editItem"
                        :website="website"
                    />
                </v-col>
            </v-row>
            <confirm-dialog
                :openDialog="dialogDelete"
                :onClicked="deleteItemConfirm"
                :onClose="closeDelete"
                toolBarColor="red darken-2"
            ></confirm-dialog>
            <Website-Dialog
                :dialog="dialog"
                :close="close"
                :editedIndex="editedIndex"
                :editedItem="editedItem"
                :refreshData="refreshData"
                :key="dialog"
            />
        </v-card>
    </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";
import WebsiteCard from "./WebsiteCard.vue";
import CompanyWebsitesApi from "../../Api/CompanyWebsitesApi";
import WebsiteDialog from "./WebsiteDialog.vue";

export default {
    components: { PageHeader, ConfirmDialog, WebsiteCard, WebsiteDialog },
    data() {
        return {
            loading: true,
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            websites: [],
            editedItem: {},
            defaultItem: {},
            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        if (!this.isInRole(this.roles.companyWebsite.get))
            this.redirectUnauthorizedUsers();
        this.refreshData();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.websites.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.websites.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            try {
                var id = this.websites[this.editedIndex].companyWebsiteGuid;

                CompanyWebsitesApi.Delete(id)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.closeDelete();
                        this.refreshData();
                    });
            } catch (error) {
                console.log(error);
            }
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.currFiles = [];
            this.files = [];
        },
        async refreshData() {
            this.loading = true;
            this.websites = await CompanyWebsitesApi.GetSync();
            this.loading = false;
        }
    }
};
</script>
