<template>
    <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card>
            <v-card-title class="pa-0">
                <v-toolbar color="primary" elevation="0" dark>
                    {{ editedIndex == -1 ? $t("add") : $t("edit") }}
                    {{ $t("companyWebsites.website") }}
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-container class="my-1" fluid
                    ><v-form ref="form" v-model="valid" lazy-validation>
                        <v-row
                            ><v-col cols="12" md="12">
                                <v-text-field
                                    :outlined="outlined"
                                    hide-details
                                    :dense="dense"
                                    v-model="editedItem.name"
                                    :rules="rules"
                                    :label="$t('companyWebsites.name')"
                                ></v-text-field> </v-col
                        ></v-row>
                        <v-row
                            ><v-col cols="12" md="12">
                                <v-text-field
                                    :outlined="outlined"
                                    hide-details
                                    :dense="dense"
                                    v-model="editedItem.url"
                                    :rules="rules"
                                    :label="$t('companyWebsites.url')"
                                ></v-text-field> </v-col
                        ></v-row>
                        <v-row
                            ><v-col cols="12" md="12">
                                <v-textarea
                                    :label="$t('companyWebsites.description')"
                                    :outlined="outlined"
                                    :dense="dense"
                                    hide-details
                                    rows="3"
                                    v-model="editedItem.description"
                                >
                                </v-textarea> </v-col
                        ></v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-file-input
                                    v-model="coverImage"
                                    small-chips
                                    show-size
                                    clearable
                                    prepend-icon=""
                                    append-icon="mdi-paperclip"
                                    :label="$t('companyWebsites.icon')"
                                    hide-details
                                    :outlined="outlined"
                                    :dense="dense"
                                    @click:clear="clearFiles"
                                    @change="inputChanged"
                                >
                                </v-file-input>
                            </v-col>
                            <v-col
                                v-if="
                                    editedItem.files != undefined &&
                                        editedItem.files != null
                                "
                                cols="12"
                                md="6"
                            >
                                <Custom-Image
                                    :image="editedItem.files"
                                    :maxWidth="250"
                                    :onDelete="deleteFile"
                                />
                            </v-col>
                        </v-row> </v-form
                ></v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    v-if="
                        isInRole(roles.companyWebsite.create) ||
                            isInRole(roles.companyWebsite.update)
                    "
                    color="primary darken-1 white--text"
                    @click="save"
                    :disabled="!valid"
                    :min-width="100"
                    :loading="loading"
                >
                    <v-icon>mdi-content-save-outline</v-icon>
                    {{ $t("save") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="closeDialog">
                    {{ $t("cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <confirm-dialog
            :openDialog="deleteFileDialog"
            :onClicked="deleteFileConfirm"
            :onClose="closeDeleteFile"
            toolBarColor="red darken-2"
        ></confirm-dialog>
    </v-dialog>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import CustomImage from "../../components/CustomImage.vue";
import { DataValidation } from "../../BusinessLogic/DataValidation";
import CompanyWebsitesApi from "../../Api/CompanyWebsitesApi";

const dataValidation = new DataValidation();

export default {
    props: ["editedItem", "editedIndex", "dialog", "close", "refreshData"],
    components: {
        ConfirmDialog,
        CustomImage
    },
    data() {
        return {
            outlined: true,
            dense: true,
            coverPreviewPath: null,
            coverImage: null,
            uploadedCoverImage: null,
            deletedFile: null,
            deleteFileDialog: false,
            valid: true,
            loading: false,
            rules: [value => !!value || this.$t("required")]
        };
    },
    watch: {
        dialog(val) {
            val || this.closeDialog();
        }
    },
    methods: {
        closeDialog() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.close();
        },
        save() {
            if (!this.$refs.form.validate()) return;

            var website = new FormData();

            website.append("files", this.coverImage);

            for (var key in this.editedItem) {
                if (this.editedItem[key])
                    website.append(key, this.editedItem[key]);
            }

            var savePromise = null;

            if (
                !dataValidation.CheckGuidValidation(
                    this.editedItem.companyWebsiteGuid
                )
            )
                savePromise = CompanyWebsitesApi.Create(website);
            else savePromise = CompanyWebsitesApi.Update(website);

            this.loading = true;

            savePromise
                .then(response => {
                    if (response.data.status == this.responseStatus.ok) {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                        this.close();
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                    this.refreshData();
                });
        },
        inputChanged(e) {
            if (e == null || e == undefined) return;
            this.coverPreviewPath = URL.createObjectURL(e);
        },
        async deleteFileConfirm() {
            try {
                var res = await CompanyWebsitesApi.DeleteAttachment(
                    this.deletedFile,
                    this.editedItem.companyWebsiteGuid
                );

                if (res.status == this.responseStatus.ok) {
                    this.$toast.success(
                        this.$t("operationAccomplishedSuccessfully")
                    );
                    this.refreshData();
                    this.close();
                } else {
                    this.$toast.error(this.$t("error." + res.message));
                }

                this.deleteFileDialog = false;
            } catch (error) {
                console.log(error);
            }
        },
        deleteFile(file) {
            this.deletedFile = file;
            this.deleteFileDialog = true;
        },
        closeDeleteFile() {
            this.deleteFileDialog = false;
        },
        downloadFile(file) {
            window.open(file.path);
        },
        clearFiles() {
            this.coverImage = null;
            this.uploadedCoverImage = null;
            this.coverPreviewPath = null;
        }
    }
};
</script>
