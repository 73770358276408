<template>
    <v-card
        class="ma-2"
        max-height="165"
        min-height="165"
        max-width="350"
        min-width="350"
        outlined
        shaped
    >
        <v-list-item three-line>
            <v-list-item-avatar
                style="border-radius: 10px !important;"
                tile
                width="85"
                height="100%"
                min-width="85"
                color="grey"
            >
                <v-img
                    class="rounded-sm"
                    v-if="website.icon != null && !imageNotFound"
                    @error="imageLoadError"
                    :src="website.icon"
                    :alt="website.name"
                />
                <v-icon x-large dark v-else>
                    mdi-web
                </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="mb-2" style="color: #009688; font-size:15px">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        :href="'//' + website.url"
                        >{{ website.url }}</a
                    >
                </div>
                <v-list-item-title class="mb-2 text-wrap">
                    {{ website.name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{
                    website.description
                }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn
                v-if="isInRole(roles.companyWebsite.update)"
                color="primary darken-1"
                icon
                class="mx-2 my-1 white--text"
                @click="editItem(website)"
            >
                <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                v-if="isInRole(roles.companyWebsite.delete)"
                icon
                color="red"
                class="mx-2 my-1"
                @click="deleteItem(website)"
            >
                <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props: ["deleteItem", "editItem", "website"],
    data() {
        return {
            imageNotFound: false
        };
    },
    methods: {
        imageLoadError() {
            this.imageNotFound = true;
        }
    }
};
</script>
