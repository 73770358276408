import axios from "axios";
const baseController = "CompanyWebsites";

const CompanyWebsitesApi = {
    Get(id = null) {
        return axios
            .get(`${baseController}/Get`, {
                params: { id: id }
            })
            .then(response => response);
    },
    async GetSync(id = null) {
        const res = await axios.get(`${baseController}/Get`, {
            params: { id: id }
        });

        return await res.data.data;
    },
    Delete(id) {
        return axios
            .delete(`${baseController}/Delete`, {
                params: { id: id }
            })
            .then(response => response);
    },
    Create(website) {
        return axios
            .post(`${baseController}/Create`, website)
            .then(response => response);
    },
    Update(website) {
        return axios
            .put(`${baseController}/Update`, website)
            .then(response => response);
    },
    async DeleteAttachment(fileName, baseGuid) {
        const res = await axios.post(`${baseController}/DeleteAttachment`, {
            fileName: fileName,
            baseGuid: baseGuid
        });

        return await res.data;
    }
};

export default CompanyWebsitesApi;
